@mixin mat-list-icon($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
}

@mixin bottom-value($bottom: 2px){
  bottom: $bottom !important;
}

@mixin mat-chip() {
  padding: 1px 7px;
}

@mixin sidemenu-item($theme, $icon-size) {
  .sidenav-dropdown-indicator {
    transition: transform 0.25s;

    &.indicateOpen {
      transform: rotate(180deg);
    }
  }
  mat-nav-list {
    overflow: hidden;
    padding-top: 0px;
  }
  .primary {
    transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    padding-top: 0px;
  }
  .secondaryMenu {
    padding-left: 18% !important;
    transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    padding-top: 0px;
  }

  .mat-nav-list .mat-list-item .mat-list-icon {
    @include mat-list-icon($icon-size);
  }
  .mat-chip:not(.mat-basic-chip) {
    @include mat-chip();
  }
  .mat-nav-list .mat-list-item {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
  }
}

h3 {
  height: 18px;
}

.hover-animation{
  &-primary {
    @include bottom-value(0);
  }
  &-secondary > div.before{
    @include bottom-value(4px);
    background-color: #3D425459 !important;
    width: 170px !important;
  }
}

.hover-animation > span > div.before {
  content: '';
  background-color: #383C4E;
  position: absolute;
  left: 4px;
  bottom: 8px;
  width: 100%;
  height: 0;
  z-index: 0;
  margin: auto;
}

.z-index-1 {
  z-index: 1;
}

.hover-animation:hover > span > div.before {
  height: 42px;
  border-radius: 5px;
  width: 210px;
}

.hover-animation:hover > span > div.before-secondary {
  height: 42px;
  border-radius: 5px;
  width: 170px;
  top: 5px;
}

.hover-animation-submenu > div.before {
  content: '';
  background-color: #3d425459;
  position: absolute;
  left: -2%;
  bottom: 4px;
  width: 100%;
  height: 0;
  z-index: 0;
  margin: auto;
}

.hover-animation-submenu:hover > span > div.before {
  height: 34px;
  border-radius: 3px;
  width: 100%;
}

.hover-animation-icons-only:hover > span > div.before {
  border-radius: 5px 0 0 5px !important;
  height: 45px;
  width: 60px;
  margin-left: 0;
  padding-right: 5px;
  left: 4px !important;
  bottom: 7px !important;
}

.is-active > span > div.before {
  height: 42px;
  border-radius: 5px;
  width: 210px
}

.is-active > span > div.before-secondary {
  height: 42px;
  border-radius: 5px;
  width: 170px;
  top: 5px;
}

.is-active-icons-only > span > div.before {
  height: 42px;
  border-radius: 5px;
  width: 40px;
  margin-left: 2px;
  left: 1px !important;
}

.is-active-submenu > span > div.before{
  height: 34px;
  border-radius: 3px;
  width: 100%;
}

.mat-list-item:hover {
  background: none !important;
}

.mat-list-option:not(.mat-list-item-disabled):hover, .mat-nav-list .mat-list-item:not(.mat-list-item-disabled):hover {
  background: 0 0 !important;
}

cdk-sidemenu .mat-nav-list .mat-list-item.mat-list-item-focus {
  background: none !important;
}

.block {
  display : block;
}

.none {
  display : none!important;
}

.overflow-visible{
  overflow: visible !important;
}

.before {
  position: absolute;
  background: #383C4E;

  &-sub {
    background: #2A2D3C !important;
  }

  &-secondary {
    left: 0;
  }
}

.hover-element {
  font-size: 14px !important;
  color: white;
  background: #383C4E !important;
  position: absolute;
  left: 36px !important;
  height: 45px;
  display: flex;
  align-items: center;
  top: -24px;
  padding-left: 10px;
  min-width: 148px;
  width: 210px !important;

  &-submenu {
    font-size: 13px !important;
    color: white;
    background: #2A2D3C !important;
    position: absolute;
    left: 38px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    top: 21px !important;
    padding: 5px 5px 5px 10px;
    min-width: 148px;
    width: 208px !important;
    border-radius: 0 0 5px 0 !important;
    border-left: 1px solid #3D425477;
  }
}

.border-radius {
  &-top {
    border-radius: 0 5px 0 0 !important;
  }

  &-side {
    border-radius: 0 5px 5px 0 !important;
  }

}

.mat-nav-list .mat-list-item .mat-list-item-content {
  padding: 0 10px !important;
}

