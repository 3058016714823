@mixin sidemenu($theme) {
}

.sidebarSync {
  background: #2a3040;
  position: absolute;
  bottom: 30px;
  color: white;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  padding: 15px 0px;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.syncSideMenu {
  font-size: 14px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}

.lastSyncSideMenu {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.ripple {
  // margin: auto;
  // margin-top: 5rem;
  // background-color: #fff;
  // width: 30px;
  // height: 30px;
  border-radius: 50%;
  display: grid;
  animation: ripple 3s ease-out infinite;
}

.ripple::before,
.ripple::after {
  content: "";
  // grid-area: 1/1;
  border-radius: 50%;
  animation: inherit;
  animation-delay: 5s;
  
}

.ripple::after {
  animation-delay: 5s;
  width: 15px !important;
  height: 15px !important;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0.75rem rgba(255, 255, 255, 0.2);
    
  }
  100% {
    box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0);
  }
}
.imageHover:hover {
  background-color: #40465B66 !important;
  border-radius: 50%;
  // transition: 0.25s ease-in background-color, 1rem ease-in opacity, 5s ease-out transform;
  cursor: pointer;
  
}
:host::ng-deep .ps {
  position: static;
  overflow: visible !important;
  display: block;
  width: inherit;
  /* height: inherit; */
  max-width: inherit;
  max-height: inherit;
  height: 100% !important;
}

.RippleBefore{
  margin-top: 6px;
  font: normal normal normal 12px/17px Open Sans, sans-serif;
  font-size: 12px;

}
.RippleAfter{
  margin-top: -13px;
  font: normal normal normal 12px/17px Open Sans, sans-serif;
  font-size: 12px;

}


.ps__thumb-y{
  width: 2px !important;
}

.ps__thumb-x{
  width: 0 !important;
}

perfect-scrollbar>.ps.ps--active-y>.ps__rail-y {
  width: 0 !important;
}

perfect-scrollbar>.ps.ps--active-y>.ps__rail-x {
  width: 2px !important;
}

:host.material-icons {
  color: white !important;
  font-size: 18px !important;
  height: 18px !important;
  width: 18px !important;
}

.icon-toggle {
  border :1px solid #3D425477 !important;
  border-radius: 0 5px 5px 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 20px;
  bottom: 52px;
  cursor: pointer;
  background-color: #303444 !important;
  color: #FFFFFFFF !important;

  &:hover{
    background-color: #383C4E !important;
  }
}
//
//.material-icons-sidenav {
//  color: white !important;
//  font-size: 18px !important;
//  height: 18px !important;
//  width: 18px !important;
//}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.no-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.overflow{
  &-auto {
    overflow: auto !important;
  }

  &-visible {
    width: 48px!important;
  }
}

.addon-name{
  font-size: 9px;
  font-family: 'Open Sans', sans-serif;
  color: white;
}

.font-14 {
  padding-top: 5px;
  font-size: 14px !important;
}

.gap-0 {
  gap: 0 !important;
  width: 100% !important;
}

.icon{
  &-duo {
    width: 48px;
    position: absolute;
    bottom: 50px;
    display: flex!important;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  &-feedback {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    //position: absolute;
    //bottom: 105px;
    //left: 10px;
    cursor: pointer;
  }

  &-chatbot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    //position: absolute;
    //bottom: 165px;
    //left: 12px;
    cursor: pointer;
  }
}

.fw-600 {
  font-weight: 600;
}

