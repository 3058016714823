.card {
    font-size: .875rem; }
  
  *,
  *::before,
  *::after {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }

  .card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #eeeeee;
    border-radius: 0.25rem; }
    .card > hr {
      margin-right: 0;
      margin-left: 0; }
    .card > .list-group:first-child .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem; }
    .card > .list-group:last-child .list-group-item:last-child {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }
  
  .card-body {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    padding: 1.25rem; }
  
  .card-title {
    margin-bottom: 0.75rem; }
  
  .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0; }
  
  .card-text:last-child {
    margin-bottom: 0; }
  
  .card-link:hover {
    text-decoration: none; }
  
  .card-link + .card-link {
    margin-left: 1.25rem; }
  
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee; }
    .card-header:first-child {
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
    .card-header + .list-group .list-group-item:first-child {
      border-top: 0; }
  
  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border-top: 1px solid #eeeeee; }
    .card-footer:last-child {
      border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }
  
  .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0; }
  
  .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
  
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem; }
  
  .card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px); }
  
  .card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  
  .card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  
  .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .card-deck .card {
      margin-bottom: 15px; }
    @media (min-width: 576px) {
      .card-deck {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px; }
        .card-deck .card {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-flex: 1;
              -ms-flex: 1 0 0%;
                  flex: 1 0 0%;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          margin-right: 15px;
          margin-bottom: 0;
          margin-left: 15px; } }
  
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .card-group > .card {
      margin-bottom: 15px; }
    @media (min-width: 576px) {
      .card-group {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap; }
        .card-group > .card {
          -webkit-box-flex: 1;
              -ms-flex: 1 0 0%;
                  flex: 1 0 0%;
          margin-bottom: 0; }
          .card-group > .card + .card {
            margin-left: 0;
            border-left: 0; }
          .card-group > .card:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0; }
            .card-group > .card:first-child .card-img-top,
            .card-group > .card:first-child .card-header {
              border-top-right-radius: 0; }
            .card-group > .card:first-child .card-img-bottom,
            .card-group > .card:first-child .card-footer {
              border-bottom-right-radius: 0; }
          .card-group > .card:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0; }
            .card-group > .card:last-child .card-img-top,
            .card-group > .card:last-child .card-header {
              border-top-left-radius: 0; }
            .card-group > .card:last-child .card-img-bottom,
            .card-group > .card:last-child .card-footer {
              border-bottom-left-radius: 0; }
          .card-group > .card:only-child {
            border-radius: 0.25rem; }
            .card-group > .card:only-child .card-img-top,
            .card-group > .card:only-child .card-header {
              border-top-left-radius: 0.25rem;
              border-top-right-radius: 0.25rem; }
            .card-group > .card:only-child .card-img-bottom,
            .card-group > .card:only-child .card-footer {
              border-bottom-right-radius: 0.25rem;
              border-bottom-left-radius: 0.25rem; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
            border-radius: 0; }
            .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
            .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
            .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
            .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
              border-radius: 0; } }
  
  .card-columns .card {
    margin-bottom: 0.75rem; }
  
  @media (min-width: 576px) {
    .card-columns {
      -webkit-column-count: 3;
              column-count: 3;
      -webkit-column-gap: 1.25rem;
              column-gap: 1.25rem; }
      .card-columns .card {
        display: inline-block;
        width: 100%; } }
  .card {
    border: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    border-radius: 6px;
    color: #333333;
    background: #fff;
    width: 100%;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
    .card .card-category:not([class*="text-"]) {
      color: #999999; }
    .card .card-category {
      margin-top: 10px; }
      .card .card-category .material-icons {
        position: relative;
        top: 8px;
        line-height: 0; }
    .card .form-check {
      margin-top: 15px; }
    .card .card-title {
      margin-top: 0.625rem; }
      .card .card-title:last-child {
        margin-bottom: 0; }
    .card.no-shadow .card-header-image,
    .card.no-shadow .card-header-image img {
      -webkit-box-shadow: none !important;
              box-shadow: none !important; }
    .card .card-body,
    .card .card-footer {
      padding: 0.9375rem 1.875rem; }
    .card .card-body + .card-footer {
      padding-top: 0rem;
      border: 0;
      border-radius: 6px; }
    .card .card-footer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background-color: transparent;
      border: 0; }
      .card .card-footer .author,
      .card .card-footer .stats {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex; }
      .card .card-footer .stats {
        color: #999999; }
        .card .card-footer .stats .material-icons {
          position: relative;
          top: -10px;
          margin-right: 3px;
          margin-left: 3px;
          font-size: 18px; }
    .card.bmd-card-raised {
      -webkit-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
              box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2); }
    @media (min-width: 992px) {
      .card.bmd-card-flat {
        -webkit-box-shadow: none;
                box-shadow: none; } }
    .card .card-header {
      border-bottom: none;
      background: transparent; }
      .card .card-header .title {
        color: #fff; }
      .card .card-header .nav-tabs {
        padding: 0; }
      .card .card-header.card-header-image {
        position: relative;
        padding: 0;
        z-index: 1;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: -30px;
        border-radius: 6px; }
        .card .card-header.card-header-image img {
          width: 100%;
          border-radius: 6px;
          pointer-events: none;
          -webkit-box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
                  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }
        .card .card-header.card-header-image .card-title {
          position: absolute;
          bottom: 15px;
          left: 15px;
          color: #fff;
          font-size: 1.125rem;
          text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5); }
        .card .card-header.card-header-image .colored-shadow {
          -webkit-transform: scale(0.94);
                  transform: scale(0.94);
          top: 12px;
          -webkit-filter: blur(12px);
                  filter: blur(12px);
          position: absolute;
          width: 100%;
          height: 100%;
          background-size: cover;
          z-index: -1;
          -webkit-transition: opacity .45s;
          transition: opacity .45s;
          opacity: 0; }
        .card .card-header.card-header-image.no-shadow {
          -webkit-box-shadow: none;
                  box-shadow: none; }
          .card .card-header.card-header-image.no-shadow.shadow-normal {
            -webkit-box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
                    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }
          .card .card-header.card-header-image.no-shadow .colored-shadow {
            display: none !important; }
    .card .card-header-primary .card-icon,
    .card .card-header-primary .card-text,
    .card .card-header-primary:not(.card-header-icon):not(.card-header-text), .card.bg-primary,
    .card.card-rotate.bg-primary .front,
    .card.card-rotate.bg-primary .back {
      background: linear-gradient(60deg, #ab47bc, #8e24aa); }
    .card .card-header-info .card-icon,
    .card .card-header-info .card-text,
    .card .card-header-info:not(.card-header-icon):not(.card-header-text), .card.bg-info,
    .card.card-rotate.bg-info .front,
    .card.card-rotate.bg-info .back {
      background: linear-gradient(60deg, #26c6da, #00acc1); }
    .card .card-header-success .card-icon,
    .card .card-header-success .card-text,
    .card .card-header-success:not(.card-header-icon):not(.card-header-text), .card.bg-success,
    .card.card-rotate.bg-success .front,
    .card.card-rotate.bg-success .back {
      background: linear-gradient(60deg, #66bb6a, #43a047); }
    .card .card-header-warning .card-icon,
    .card .card-header-warning .card-text,
    .card .card-header-warning:not(.card-header-icon):not(.card-header-text), .card.bg-warning,
    .card.card-rotate.bg-warning .front,
    .card.card-rotate.bg-warning .back {
      background: linear-gradient(60deg, #ffa726, #fb8c00); }
    .card .card-header-danger .card-icon,
    .card .card-header-danger .card-text,
    .card .card-header-danger:not(.card-header-icon):not(.card-header-text), .card.bg-danger,
    .card.card-rotate.bg-danger .front,
    .card.card-rotate.bg-danger .back {
      background: linear-gradient(60deg, #ef5350, #e53935); }
    .card .card-header-rose .card-icon,
    .card .card-header-rose .card-text,
    .card .card-header-rose:not(.card-header-icon):not(.card-header-text), .card.bg-rose,
    .card.card-rotate.bg-rose .front,
    .card.card-rotate.bg-rose .back {
      background: linear-gradient(60deg, #ec407a, #d81b60); }
    .card .card-header-primary .card-icon,
    .card .card-header-primary:not(.card-header-icon):not(.card-header-text),
    .card .card-header-primary .card-text {
      -webkit-box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
              box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4); }
    .card .card-header-danger .card-icon,
    .card .card-header-danger:not(.card-header-icon):not(.card-header-text),
    .card .card-header-danger .card-text {
      -webkit-box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4);
              box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4); }
    .card .card-header-rose .card-icon,
    .card .card-header-rose:not(.card-header-icon):not(.card-header-text),
    .card .card-header-rose .card-text {
      -webkit-box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
              box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4); }
    .card .card-header-warning .card-icon,
    .card .card-header-warning:not(.card-header-icon):not(.card-header-text),
    .card .card-header-warning .card-text {
      -webkit-box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
              box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4); }
    .card .card-header-info .card-icon,
    .card .card-header-info:not(.card-header-icon):not(.card-header-text),
    .card .card-header-info .card-text {
      -webkit-box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
              box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4); }
    .card .card-header-success .card-icon,
    .card .card-header-success:not(.card-header-icon):not(.card-header-text),
    .card .card-header-success .card-text {
      -webkit-box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
              box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4); }
    .card [class*="card-header-"], .card[class*="bg-"] {
      color: #fff; }
      .card [class*="card-header-"] .card-title a,
      .card [class*="card-header-"] .card-title,
      .card [class*="card-header-"] .icon i, .card[class*="bg-"] .card-title a,
      .card[class*="bg-"] .card-title,
      .card[class*="bg-"] .icon i {
        color: #fff; }
      .card [class*="card-header-"] .icon i, .card[class*="bg-"] .icon i {
        border-color: rgba(255, 255, 255, 0.25); }
      .card [class*="card-header-"] .author a,
      .card [class*="card-header-"] .stats,
      .card [class*="card-header-"] .card-category,
      .card [class*="card-header-"] .card-description, .card[class*="bg-"] .author a,
      .card[class*="bg-"] .stats,
      .card[class*="bg-"] .card-category,
      .card[class*="bg-"] .card-description {
        color: rgba(255, 255, 255, 0.8); }
      .card [class*="card-header-"] .author a:hover, .card [class*="card-header-"] .author a:focus, .card [class*="card-header-"] .author a:active, .card[class*="bg-"] .author a:hover, .card[class*="bg-"] .author a:focus, .card[class*="bg-"] .author a:active {
        color: #fff; }
    .card .author .avatar {
      width: 30px;
      height: 30px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 5px; }
    .card .author a {
      color: #3C4858;
      text-decoration: none; }
      .card .author a .ripple-container {
        display: none; }
    .card .card-category-social .fa {
      font-size: 24px;
      position: relative;
      margin-top: -4px;
      top: 2px;
      margin-right: 5px; }
    .card .card-category-social .material-icons {
      position: relative;
      top: 5px; }
    .card[class*="bg-"],
    .card[class*="bg-"] .card-body {
      border-radius: 6px; }
      .card[class*="bg-"] h1 small,
      .card[class*="bg-"] h2 small,
      .card[class*="bg-"] h3 small,
      .card[class*="bg-"] .card-body h1 small,
      .card[class*="bg-"] .card-body h2 small,
      .card[class*="bg-"] .card-body h3 small {
        color: rgba(255, 255, 255, 0.8); }
    .card .card-stats {
      background: transparent;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .card .card-stats .author,
      .card .card-stats .stats {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex; }
  
  .card-plain {
    background: transparent;
    -webkit-box-shadow: none !important;
            box-shadow: none !important; }
    .card-plain .card-header:not(.card-avatar) {
      margin-left: 0;
      margin-right: 0; }
      .card-plain .card-header:not(.card-avatar) .card-category,
      .card-plain .card-header:not(.card-avatar) .card-description {
        color: #999999; }
    .card-plain .card-body {
      padding-left: 5px;
      padding-right: 5px; }
    .card-plain .card-header-image {
      margin: 0 !important;
      border-radius: 6px; }
      .card-plain .card-header-image img {
        border-radius: 6px; }
    .card-plain .card-footer {
      padding-left: 5px;
      padding-right: 5px;
      background-color: transparent; }
  
  .card-testimonial, .card-profile {
    margin-top: 30px;
    text-align: center; }
    .card-testimonial .card-avatar, .card-profile .card-avatar {
      margin: -50px auto 0;
      border-radius: 50%;
      overflow: hidden;
      padding: 0;
      -webkit-box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
              box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }
      .card-testimonial .card-avatar + .card-body, .card-profile .card-avatar + .card-body {
        margin-top: 15px; }
      .card-testimonial .card-avatar img, .card-profile .card-avatar img {
        width: 100%;
        height: auto; }
    .card-testimonial .card-body + .card-footer, .card-profile .card-body + .card-footer {
      margin-top: -15px; }
    .card-testimonial .card-footer .btn.btn-just-icon, .card-profile .card-footer .btn.btn-just-icon {
      font-size: 20px;
      padding: 12px 12px;
      line-height: 1em; }
    .card-plain.card-testimonial .card-avatar, .card-plain.card-profile .card-avatar {
      margin-top: 0; }
  
  .card-testimonial .card-avatar {
    max-width: 100px;
    max-height: 100px; }
  
  .card-testimonial .card-footer {
    margin-top: 0;
    display: block; }
    .card-testimonial .card-footer .card-avatar {
      margin-top: 10px;
      margin-bottom: -60px; }
  
  .card-testimonial .card-description {
    font-style: italic; }
    .card-testimonial .card-description + .card-title {
      margin-top: 30px; }
  
  .card-testimonial .icon {
    margin-top: 30px; }
    .card-testimonial .icon .material-icons {
      font-size: 40px; }
  
  .card-profile .card-header:not([class*="card-header-"]) {
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none; }
  
  .card-profile .card-avatar {
    max-width: 130px;
    max-height: 130px; }
  
  .card {
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14); }
    .card .table tr:first-child td {
      border-top: none; }
    .card .card-title {
      margin-top: 0;
      margin-bottom: 3px; }
    .card .card-body {
      padding: 0.9375rem 20px;
      position: relative; }
    .card .card-header {
      z-index: 3 !important; }
      .card .card-header .card-category {
        margin: 0; }
      .card .card-header.card-header-text {
        display: inline-block; }
        .card .card-header.card-header-text:after {
          content: "";
          display: table; }
      .card .card-header.card-header-icon i, .card .card-header.card-header-text i {
        width: 33px;
        height: 33px;
        text-align: center;
        line-height: 33px; }
      .card .card-header.card-header-icon .card-title, .card .card-header.card-header-text .card-title {
        margin-top: 15px;
        color: #3C4858; }
      .card .card-header.card-header-icon h4, .card .card-header.card-header-text h4 {
        font-weight: 300; }
      .card .card-header.card-header-tabs .nav-tabs {
        background: transparent;
        padding: 0; }
      .card .card-header.card-header-tabs .nav-tabs-title {
        float: left;
        padding: 10px 10px 10px 0;
        line-height: 24px; }
    .card.card-plain .card-header.card-header-icon + .card-body .card-title,
    .card.card-plain .card-header.card-header-icon + .card-body .card-category {
      margin-top: -20px; }
    .card.card-chart .card-footer i:nth-child(1n+2) {
      width: 18px;
      text-align: center; }
    .card.card-chart .card-category {
      margin: 0; }
    .card .card-body + .card-footer,
    .card .card-footer {
      padding: 0;
      padding-top: 10px;
      margin: 0 15px 10px;
      border-radius: 0;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .card .card-body + .card-footer h6,
      .card .card-footer h6 {
        width: 100%; }
      .card .card-body + .card-footer .stats,
      .card .card-footer .stats {
        color: #999999;
        font-size: 12px;
        line-height: 22px; }
        .card .card-body + .card-footer .stats .card-category,
        .card .card-footer .stats .card-category {
          padding-top: 7px;
          padding-bottom: 7px;
          margin: 0; }
        .card .card-body + .card-footer .stats .material-icons,
        .card .card-footer .stats .material-icons {
          position: relative;
          top: 4px;
          font-size: 16px; }
    .card [class*="card-header-"] {
      margin: 0px 15px 0;
      padding: 0;
      position: relative; }
      .card [class*="card-header-"]:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
        border-radius: 3px;
        margin-top: -20px;
        padding: 15px; }
      .card [class*="card-header-"] .card-icon,
      .card [class*="card-header-"] .card-text {
        border-radius: 3px;
        background-color: #999999;
        padding: 15px;
        margin-top: -20px;
        margin-right: 15px;
        float: left; }
      .card [class*="card-header-"] .card-text {
        float: none;
        display: inline-block;
        margin-right: 0; }
        .card [class*="card-header-"] .card-text .card-title {
          color: #fff;
          margin-top: 0; }
      .card [class*="card-header-"] .card-title + .card-category {
        color: rgba(255, 255, 255, 0.62); }
        .card [class*="card-header-"] .card-title + .card-category a {
          color: #fff; }
      .card [class*="card-header-"] .ct-chart .card-title {
        color: #fff; }
      .card [class*="card-header-"] .ct-chart .card-category {
        margin-bottom: 0;
        color: rgba(255, 255, 255, 0.62); }
      .card [class*="card-header-"] .ct-chart .ct-label {
        color: rgba(255, 255, 255, 0.7); }
      .card [class*="card-header-"] .ct-chart .ct-grid {
        stroke: rgba(255, 255, 255, 0.2); }
      .card [class*="card-header-"] .ct-chart .ct-series-a .ct-point,
      .card [class*="card-header-"] .ct-chart .ct-series-a .ct-line,
      .card [class*="card-header-"] .ct-chart .ct-series-a .ct-bar,
      .card [class*="card-header-"] .ct-chart .ct-series-a .ct-slice-donut {
        stroke: rgba(255, 255, 255, 0.8); }
      .card [class*="card-header-"] .ct-chart .ct-series-a .ct-slice-pie,
      .card [class*="card-header-"] .ct-chart .ct-series-a .ct-area {
        fill: rgba(255, 255, 255, 0.4); }
      .card [class*="card-header-"] .ct-chart .ct-series-a .ct-bar {
        stroke-width: 10px; }
      .card [class*="card-header-"] .ct-chart .ct-point {
        stroke-width: 10px;
        stroke-linecap: round; }
      .card [class*="card-header-"] .ct-chart .ct-line {
        fill: none;
        stroke-width: 4px; }
    .card [data-header-animation="true"] {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
      transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1); }
    .card:hover [data-header-animation="true"] {
      -webkit-transform: translate3d(0, -50px, 0);
      transform: translate3d(0, -50px, 0); }
    .card.card-stats .card-header.card-header-icon, .card.card-stats .card-header.card-header-text {
      text-align: right; }
    .card.card-stats .card-header .card-icon + .card-title,
    .card.card-stats .card-header .card-icon + .card-category {
      padding-top: 10px; }
    .card.card-stats .card-header .card-title,
    .card.card-stats .card-header .card-category {
      margin: 0; }
    .card.card-stats .card-header .card-category {
      margin-bottom: 0;
      margin-top: 0; }
      .card.card-stats .card-header .card-category:not([class*="text-"]) {
        color: #999999;
        font-size: 14px; }
    .card.card-stats .card-header + .card-footer {
      border-top: 1px solid #eee;
      margin-top: 14px; }
    .card.card-stats .card-header i {
      font-size: 36px;
      line-height: 56px;
      width: 56px;
      height: 56px;
      text-align: center; }
    .card.card-stats .card-body {
      text-align: right; }
    .card .map {
      height: 280px;
      border-radius: 6px;
      margin-top: 15px; }
      .card .map.map-big {
        height: 420px; }
    .card .card-body.table-full-width {
      padding: 0; }
    .card .card-plain .card-header-icon {
      margin-right: 15px !important; }
  
  .table-sales {
    margin-top: 40px; }
  
  .iframe-container {
    margin: 0 -20px 0; }
    .iframe-container iframe {
      width: 100%;
      height: 500px;
      border: 0;
      -webkit-box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
              box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }
  
  .card-chart .card-body + .card-footer,
  .card-product .card-body + .card-footer {
    border-top: 1px solid #eee; }
  
  .card-product .price {
    color: inherit; }
  
  .card-collapse {
    margin-bottom: 15px; }
    .card-collapse .card .card-header a[aria-expanded="true"] {
      color: #e91e63; }
  
  .card .card-body .col-form-label,
  .card .card-body .label-on-right {
    padding: 16px 5px 0 0;
    text-align: right; }
  
  .card .card-body .label-on-right {
    text-align: left; }
  
  .card .card-body .form-group {
    margin: 27px 0 0; }
  
  .card .label-on-right code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px; }
  
  .card .tab-content .form-check {
    margin-top: -15px; }
  
  .card-wizard .input-group .form-group {
    width: 80%; }
  
    .card form {
      margin: 0; }
  
  
  .card .tab-content.tab-space {
    padding: 20px 0 9px 0; }
  
  .card-icon.icon-primary {
    color: #9c27b0; }
  
  .card-icon.icon-info {
    color: #00bcd4; }
  
  .card-icon.icon-success {
    color: #4caf50; }
  
  .card-icon.icon-warning {
    color: #ff9800; }
  
  .card-icon.icon-danger {
    color: #f44336; }
  
  .card-icon.icon-rose {
    color: #e91e63; }
  
  .card-icon.icon-white {
    color: #fff; }
  
  .card-title,
  .card-title a,
  
  .description,
  .card-description,
  .footer-big p {
    color: #999999; }
  
  .card .card-body h4.card-title {
    margin-bottom: 15px; }

  
  .card-nav-tabs {
    margin-top: 45px; }
    .card-nav-tabs .card-header {
      margin-top: -30px !important; }
  .card-collapse .card-header a i {
    -webkit-transition: all 150ms ease 0s;
    transition: all 150ms ease 0s; }
  
  .signup-page .card-signup form .form-check {
    padding-left: 20px; }
    .signup-page .card-signup form .form-check .form-check-label {
      padding-left: 35px; }
  
  .section-signup .card-signup .card-header {
    width: auto; }
  
  .section-signup .card-signup .card-body .input-group {
    padding-bottom: 7px;
    margin: 27px 0 0 0; }
  
  .login-page .card-login {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
    .login-page .card-login.card-hidden {
      opacity: 0;
      -webkit-transform: translate3d(0, -60px, 0);
      transform: translate3d(0, -60px, 0); }
    .login-page .card-login .card-header {
      margin-top: -40px;
      margin-bottom: 20px; }
    .login-page .card-login .card-body {
      padding: 0px 30px 0px 10px; }
      .login-page .card-login .card-body .input-group .input-group-text {
        padding: 15px 15px 0 15px; }
      .login-page .card-login .card-body .input-group .form-control {
        padding-bottom: 10px;
        margin: 17px 0 0 0; }
    .login-page .card-login .social-line .btn {
      margin-left: 5px;
      margin-right: 5px; }
    .login-page .card-login.card-hidden {
      opacity: 0;
      -webkit-transform: translate3d(0, -60px, 0);
      transform: translate3d(0, -60px, 0); }
  
  .lock-page .card-profile {
    width: 240px;
    margin: 60px auto 0;
    color: #FFFFFF;
    left: 0;
    right: 0;
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
    .lock-page .card-profile.card-hidden {
      opacity: 0;
      -webkit-transform: translate3d(0, -60px, 0);
      transform: translate3d(0, -60px, 0); }
    .lock-page .card-profile .card-avatar {
      max-width: 90px;
      max-height: 90px;
      margin-top: -45px; }
    .lock-page .card-profile .card-footer {
      border: none;
      padding-top: 0; }
    .lock-page .card-profile .form-group {
      text-align: left;
      margin-top: 15px; }
    .lock-page .card-profile.with-animation {
      -webkit-transition: 300ms, ease-in;
      transition: 300ms, ease-in; }
    .lock-page .card-profile .card-body + .card-footer {
      padding: 0.9375rem 1.875rem;
      margin: 0; }
  
  
  .register-page .card-signup {
    border-radius: 6px;
    -webkit-box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
            box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    margin-bottom: 100px;
    padding: 40px 0px;
    margin-top: 15vh; }
    .register-page .card-signup .info {
      max-width: 360px;
      margin: 0 auto;
      padding: 0px; }
      .register-page .card-signup .info .info-title {
        color: #3C4858;
        margin: 30px 0 15px; }
    .register-page .card-signup .form-check {
      margin-top: 20px;
      margin-bottom: 0; }
      .register-page .card-signup .form-check label {
        margin-left: 27px; }
      .register-page .card-signup .form-check .checkbox-material {
        padding-right: 20px; }
      .register-page .card-signup .form-check a {
        color: #9c27b0; }
    .register-page .card-signup .info-horizontal .icon {
      float: left;
      margin-top: 24px;
      margin-right: 10px; }
      .register-page .card-signup .info-horizontal .icon i {
        font-size: 2.6em; }
      .register-page .card-signup .info-horizontal .icon.icon-primary {
        color: #9c27b0; }
      .register-page .card-signup .info-horizontal .icon.icon-info {
        color: #00bcd4; }
      .register-page .card-signup .info-horizontal .icon.icon-success {
        color: #4caf50; }
      .register-page .card-signup .info-horizontal .icon.icon-warning {
        color: #ff9800; }
      .register-page .card-signup .info-horizontal .icon.icon-danger {
        color: #f44336; }
      .register-page .card-signup .info-horizontal .icon.icon-rose {
        color: #e91e63; }
    .register-page .card-signup .info-horizontal .description {
      overflow: hidden; }
    .register-page .card-signup .form-group {
      margin: 27px 0 0 7px;
      padding-bottom: 0; }
    .pricing-page .card-plain .icon i,
    .pricing-page .card-plain .card-title {
      color: #fff; }

    .presentation-page .section-components .coloured-card-img,
    .presentation-page .section-components .coloured-card-btn-img {
      top: -2%;
      left: 65%; }
    .presentation-page .section-components .coloured-card-img,
    .presentation-page .section-components .coloured-card-btn-img {
      top: -2%; }
  

  /*          Changes for small display      */
  @media (max-width: 991px) {
    .card .form-horizontal .label-on-left,
    .card .form-horizontal .label-on-right {
      padding-left: 15px;
      padding-top: 8px; }
    .card .form-horizontal .form-group {
      margin-top: 0px; }
    .card .form-horizontal .checkbox-radios {
      padding-bottom: 15px; }
      .card .form-horizontal .checkbox-radios .checkbox:first-child,
      .card .form-horizontal .checkbox-radios .radio:first-child {
        margin-top: 0; }
    .card .form-horizontal .checkbox-inline {
      margin-top: 0; }
  }