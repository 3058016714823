@charset "UTF-8";

/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~handsontable/dist/handsontable.full.css";
@import url("assets/css/material-dashboard.css");

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 9999 !important;
}

.md-drppicker {
  margin-top: 14% !important;
  width: max-content !important;
  font-family: "Open Sans", sans-serif;
}

.md-drppicker.double {
  margin-top: 14% !important;
  width: max-content !important;
  font-family: "Open Sans", sans-serif;
}

.mat-tab-body-wrapper {
  height: 100%;
}

/*Don't remove this*/
.required:after {
  content: " *";
  color: red;
}

.empty-data-msg {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: #212529;
  font-weight: 600;
}

.w {
  &-10 {
    width: 10% !important;
  }

  &-20 {
    width: 20% !important;
  }

  &-30 {
    width: 30% !important;
  }

  &-40 {
    width: 40% !important;
  }

  &-60 {
    width: 60% !important;
  }

  &-70 {
    width: 70% !important;
  }

  &-80 {
    width: 80% !important;
  }

  &-90 {
    width: 90% !important;
  }
}

.cursor {
  cursor: pointer !important;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

.mat-tab-labels {
  border-bottom: 1px solid #b8b8b887;
}

.mat-checkbox-frame {
  border-width: 1px !important;
  border-color: #0000004d !important;
}

.mat-checkbox-background, .mat-checkbox-frame {
  border-radius: 4px !important;
}


.mat-tab-label {
  height: 30px !important;
  padding: 0 24px;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 1 !important;
  min-width: 160px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
  color: #b8b8b8;
  border-bottom: none;
  font-weight: 400;
}

.mat-tab-label-active {
  color: #2B2F7F !important;
  font-weight: 600;
}

.mat-tab-label-active > div > span {
  color: #2B2F7F !important;
  font-weight: 600 !important;
}

.mat-ink-bar {
  height: 1px !important;
  background-color: #2B2F7F !important;
}


.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #f8f9fa !important;
}

.mat-slide-toggle {
  display: inline-block;
  height: 28px !important;
  max-width: 100%;
  line-height: 24px;
  white-space: nowrap;
  outline: 0;
}

.mat-slide-toggle-thumb-container {
  position: absolute;
  z-index: 1;
  width: 18px !important;
  height: 18px !important;
  top: 2px !important;
  left: 2px !important;
  transition: all 80ms linear;
  transition-property: transform;
  cursor: grab;
}

.mat-slide-toggle-thumb {
  height: 18px !important;
  width: 18px !important;
  border-radius: 50%;
  box-shadow: none !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #13BE1880 !important;
}

.mat-slide-toggle-bar {
  position: relative;
  width: 38px !important;
  height: 22px !important;
  flex-shrink: 0;
  border-radius: 16px !important;
}

.small-slide.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(10px, 0, 0) !important;
}

.small-slide .mat-slide-toggle-thumb-container {
  position: absolute;
  z-index: 1;
  width: 12px !important;
  height: 12px !important;
  top: 1px !important;
  left: 1px !important;
  transition: all 80ms linear;
  transition-property: transform;
  cursor: grab;
}

.small-slide .mat-slide-toggle-bar {
  position: relative;
  width: 26px !important;
  height: 16px !important;
  flex-shrink: 0;
  border-radius: 16px !important;
}

.small-slide .mat-slide-toggle-thumb {
  height: 14px !important;
  width: 14px !important;
  border-radius: 50%;
  box-shadow: none !important;
}


.mat-slide-toggle .mat-ripple-element {
  opacity: 0 !important;
  background-color: transparent;
}

.material-icons {
  cursor: pointer;
  font-family: "Material Icons", sans-serif !important;
}

.material-icons-outlined {
  cursor: pointer;
  font-family: 'Material Icons Outlined', sans-serif !important;
}

.mat-checkbox-ripple {
  display: none;
}

.table {
  &-header {
    font: normal normal 500 15px/20px Open Sans, sans-serif !important;
  }

  &-border {
    border: 1px solid #dee2e6 !important;
    border-radius: 8px !important;
    overflow: hidden;

    &-header {
      border-bottom: 1px solid #dee2e6 !important;
    }
  }
}

.font {
  &-18 {
    font: normal normal 500 18px/22px Open Sans, sans-serif;
  }

  &-normal {
    font: normal normal normal 14px/25px Open Sans, sans-serif;
  }

  &-contact {
    font: normal normal 500 15px Open Sans, sans-serif;
  }

  &-13 {
    font: normal normal normal 13px/20px Open Sans, sans-serif;
  }

  &-normal {
    font: normal normal normal 16px/25px Open Sans, sans-serif !important;
  }

  &-medium {
    font: normal normal 500 16px/25px Open Sans, sans-serif !important;
  }

  &-light {
    font: normal normal normal 15px/24px Open Sans, sans-serif !important;
  }

  &-optional {
    color: #999999;
  }
}


.fa, .fas {
  font-family: 'Font Awesome 5 Free', sans-serif !important;
  font-weight: 900 !important;
}

.btn {
  &-edit {
    font: normal normal 400 14px/20px Open Sans, sans-serif;
    cursor: pointer;

    &:hover {
      border: 1px solid #EFEFEF;
      margin: -1px;
    }
  }

  &-def {
    border-radius: 4px;
    display: flex;
    justify-content: center;
    min-width: fit-content;
    min-height: fit-content;
    font-size: 16px;
    font-weight: 500;
    padding: 0.4rem 2.3rem;
    cursor: pointer;

    &:hover {
      border: 1px solid #EFEFEF;
      margin: -1px;
    }
  }

  &-second {
    border-radius: 4px;
    display: flex;
    justify-content: center;
    min-width: fit-content;
    min-height: fit-content;
    font-size: 14px;
    font-family: Open Sans, sans-serif;
    font-weight: 500;
    padding: 0.3rem 2.3rem;
    cursor: pointer;

    &:hover {
      border: 1px solid #EFEFEF;
      margin: -1px;
    }
  }

  &-save {
    background-color: #2B2F7F;
    color: white !important;
  }
}

.chip {
  &-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 0.5rem !important;
  }

  &-item {
    background: #EFEFEF80 0 0 no-repeat padding-box;
    border-radius: 20px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-name {
      font: normal normal normal 13px/18px 'Open Sans', sans-serif;
      color: #3D4254;
      margin: 0 8px;
    }

    &-close {
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }
  }
}
